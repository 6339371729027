import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

//  base: string
//   lighten5: string
//   lighten4: string
//   lighten3: string
//   lighten2: string
//   lighten1: string
//   darken1: string
//   darken2: string
//   darken3: string
//   darken4: string

//   [name: string]: string

export default new Vuetify({
    lang: {
      locales: { pt },
      current: 'pt',
  },
  theme: {
      options: {
        customProperties: true
      },
    themes: {
      light: {
        primary: {
          base: '#85cce2',
          lighten2: "#b8ffff",
          lighten1: "#B5E4F3",
          darken1: '#539bb0',
          darken2: '#3A8FAB',
        } ,
        secondary: {
          base: '#909ee5',
          lighten1: "#BCC6F5",
          lighten2: "#c2cfff",
          darken1: '#5f70b3',
          darken2: '#495CB8',
        },
        accent:{
          base: '#86EAA8',
          lighten1: "#E8FEEF",
          lighten2: "#B5F6CB",
          darken1: '#5EDA88',
          darken2: '#3DC36A',
        } ,
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        anchor: '#8c9eff',
        textPrimary: '#000',
      }
    }
  }
});
