import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueScrollactive from 'vue-scrollactive';

import VueAnimateOnScroll from 'vue-animate-onscroll'
import VueTheMask from 'vue-the-mask'
import "animate.css"

// import Vue and vue-parallax-js
import VueParallaxJs from 'vue-parallax-js'

import vuetify from './plugins/vuetify'

Vue.use(VueAnimateOnScroll)
Vue.use(VueScrollactive);
Vue.use(VueParallaxJs)
Vue.use(VueTheMask)
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
