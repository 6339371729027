<template>
  <section class="sessao" id="quem-somos">
    <div style="margin-top: -104px; margin-bottom: 125px"></div>
    <v-card
      class="ma-2 mt-6 mx-auto pa-2"
      color="secondary"
      dark
      elevation="8"
      max-width="600"
      shaped
    >
      <v-card-title class="d-flex justify-center">
        <v-img
          alt="Logo"
          class="shrink mr-2 img-card"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="96"
        />
        <!-- <v-icon large left> mdi-twitter </v-icon> -->
        <span class="text-h4">QUEM SOMOS</span>
      </v-card-title>

      <v-card-text class="text-h6">
        Um grupo de profissionais liberais, empresários, estudantes,
        trabalhadores da iniciativa privada e pública que se reuniram para a
        execução de um projeto social em prol da melhoria da qualidade de vida
        da parcela mais necessitada de recursos da população da cidade.
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {};
</script>

<style>
#quem-somos {
  padding: 32px;
}
.img-card {
  margin-top: -65px;
  margin-bottom: 20px;
  /* transform: translate(0, -45px); */
}
</style>