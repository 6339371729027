<template>
  <section class="sessao flex justify-center" id="causas">
    <div style="margin-top: -104px; margin-bottom: 104px"></div>
    <h2 class="section-title text-h4 ma-3 mb-0">NOSSAS CAUSAS</h2>
    <p class="section-desc pa-2 pt-2">
      A ONG tem missão efetuar ações para melhorar a qualidade de vida das
      pessoas com baixa condições financeiras na cidade de Lençóis Paulista.
      <br />
      Para isso realiza ações que incluem:
    </p>
    <article class="slider-wrap">
      <hooper :settings="hooperSettings" style="height: 200px">
        <slide
          v-for="(causa, i) in causas"
          :key="`causa-${i}`"
          :index="i"
          class="slider-item"
        >
          <v-card>
            <v-img
              :src="causa.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0, 0, 0, 0.1), var(--v-primary-darken1)"
              height="200px"
            >
              <v-card-title
                v-text="causa.title"
                class="word-break"
              ></v-card-title>
            </v-img>
          </v-card>
        </slide>
        <hooper-navigation slot="hooper-addons"></hooper-navigation>
      </hooper>
    </article>
  </section>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";
export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  data: () => ({
    hooperSettings: {
      itemsToShow: 1,
      centerMode: true,
      HooperNavigation: true,
      autoPlay: true,
      infiniteScroll: true,
      playSpeed: 3500,
      transition: 500,
      breakpoints: {
        800: {
          centerMode: true,
          itemsToShow: 2,
        },
        1000: {
          centerMode: true,
          itemsToShow: 3,
        },
      },
    },
    model: 0,
    causas: [
      {
        title: "Reforma de habitações",
        src: require("../assets/causa-reforma.jpeg"),
        flex: 12,
      },
      {
        title: "Arrecadação de móveis e eletrodomésticos",
        src: require("../assets/causa-moveis.jpeg"),
        flex: 12,
      },
      {
        title: "Arrecadação de cestas básicas",
        src: require("../assets/causa-cesta.jpeg"),
        flex: 12,
      },
      {
        title: "Arrecadação de vestuário, cobertores, mantas, etc",
        src: require("../assets/causa-roupa.jpeg"),
        flex: 12,
      },
      {
        title: "Arrecadação de remédios",
        src: require("../assets/causa-remedio.jpeg"),
        flex: 12,
      },
    ],
  }),
};
</script>

<style>
.sessao {
  padding: 12px 24px;
}
.section-title {
  width: 100%;
  text-align: center;
}
.section-desc {
  text-align: center;
  font-size: 14px;
}
.slider-wrap {
  width: 100vw;
  margin-left: -24px;
}
.slider-item {
  padding: 2px;
}
.hooper-track {
  margin-left: -24px;
}
.word-break {
  overflow-wrap: break-word;
  word-break: break-word;
}
</style>