<template>
  <section class="sessao flex justify-center align-center">
    <div id="formDoacao" style="margin-top: -104px; margin-bottom: 104px"></div>
    <h2 class="section-title text-h4 ma-3 mb-4">
      COMO FAÇO PARA REALIZAR DOAÇÕES ?
    </h2>
    <p class="ma-3 mb-4">
      Com a ajuda de pessoas como você, podemos levar alívio onde ninguém mais
      chega. Seja um doador.
    </p>
    <div
      class="sessao flex justify-center align-center"
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
      "
    >
      <div
        class="align-center"
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          flex-wrap: wrap;
        "
      >
        <h1 class="ma-2 text-center">Faça a SUA DOAÇÃO e AJUDE o próximo!</h1>
        <h1
          class="ma-2 text-center"
          style="background-color: yellow; padding: 8px"
        >
          PIX CNPJ: 45.336.456/0001-65
        </h1>
        <div class="contato-wrap" style="width: fit-content">
          <v-sheet color="primary" elevation="4" class="map-wrap">
            <img
              src="../assets/qr-code.jpeg"
              alt="QR code PIX"
              width="256px"
              height="256px"
            />
          </v-sheet>
        </div>
      </div>

      <h2 class="ma-2">OU</h2>

      <div class="contato-item" style="min-width: 250px">
        <v-icon
          class="pa-4 secondary"
          style="
            grid-area: i;
            border-radius: 50%;
            height: 72px;
            align-self: center;
          "
          size="48"
          color="white"
        >
          mdi-phone-in-talk</v-icon
        >
        <span
          class="ml-2 primary--text --text-darken1 font-weight-bold"
          style="grid-area: f; font-size: 1.3rem; align-self: center"
          >Para mais informações, ligue.</span
        >
        <a
          class="ml-4 text-decoration-none"
          style="
            margin-top: 0px;
            grid-area: s;
            font-size: 1.5rem;
            align-self: center;
          "
          href="tel:+5514998785593"
          >(14)99878-5593</a
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
.contato-wrap {
}
.map-wrap {
  padding: 8px;
  flex-grow: 1;
}
.contato-item {
  padding: 12px 24px;
  display: grid;
  grid-template-areas:
    "i f f"
    "i s s";
  grid-template-columns: 72px 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
</style>