<template>
  <v-footer
    dark
    padless
    style="height: 100px; width: 100vw"
    elevation="4"
    rounded
    class="primary"
  ></v-footer>
</template>

<script>
export default {};
</script>

<style>
</style>