<template>
  <section id="home" class="hero-home">
    <article class="header-hero" style="margin-top: -10vh">
      <div
        class="hero-bg"
        v-parallax="0.3"
        :style="`background-image: url('${img1}')`"
      ></div>
      <div
        class="hero-content hero-content--right hero-content--first"
        v-parallax="0.1"
      >
        <h1 class="hero-text text-h4 text-xl-h2 font-weight-black white--text">
          A marca que deixamos na vida que tocamos, nunca desaparecerá.
        </h1>
        <!-- <p class="hero-descricao">Descrição pequena do titulo</p> -->
        <v-btn
          href="#formVoluntario"
          class="hero-callto black--text mt-4"
          color="primary"
          >SEJA UM VOLUNTÁRIO</v-btn
        >
      </div>
    </article>

    <article class="header-hero" id="conteudo">
      <div
        class="hero-bg"
        v-parallax="0.3"
        :style="`background-image: url('${img2}')`"
      ></div>
      <div class="hero-content hero-content--left" v-parallax="0.1">
        <h1 class="hero-text text-h4 text-xl-h2 font-weight-black white--text">
          Como ajudar de outras formas?
        </h1>
        <!-- <p class="hero-descricao">Descrição pequena do titulo</p> -->
        <v-btn
          class="hero-callto black--text mt-4"
          color="primary"
          elevation="2"
          href="#formDoacao"
          >FAÇA UMA DOAÇÃO</v-btn
        >
      </div>
    </article>
  </section>
</template>

<script>
export default {
  data: () => {
    return {
      img1: require("../assets/home-header/home-hero-11.jpeg"),
      img2: require("../assets/home-header/home-hero-2.jpeg"),
    };
  },
};
</script>

<style>
.header-hero {
  height: 73vh;
  width: 100vw;
  position: relative;
}
.header-hero:nth-child(even) {
  margin-top: -20vh;
  clip-path: polygon(0 20%, 100% 0%, 100% 100%, 0 100%);
  overflow: hidden;
  border-top: var(--v-primary-base) 2px solid;
}
.hero-bg::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.171);
  height: 100%;
  width: 100%;
  position: absolute;
}
.hero-bg {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
}
.hero-content {
  position: absolute;
  top: 35vh;
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
  padding: 12px;
}
.hero-content--first {
  top: 20vh;
}
.hero-content--right {
  left: 24px;
}
.hero-content--left {
  right: 24px;
}

.hero-text {
  display: block;
}
.hero-descricao {
  color: white;
}
.hero-callto {
  max-width: 248px;
}
</style>