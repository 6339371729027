<template>
  <section id="missao" class="card-list-container flex flex-column flex-lg-row">
    <article class="card-list flex flex-column primary">
      <div class="card-icon-wrap">
        <v-icon
          class="card-icon"
          size="102"
          color="white"
          v-animate-onscroll="{
            down: 'animate__animated animate__slideInUp',
            up: 'animate__animated animate__slideInDown',
          }"
          >mdi-bullseye-arrow</v-icon
        >
      </div>
      <h4 class="card-title text-h4 white--text">Missão</h4>
      <p class="card-text">
        Congregar pessoas com espírito de voluntariado para realização de ações
        que possam melhorar o bem-estar físico, mental e emocional de pessoas
        com as mais diversas necessidades.
      </p>
    </article>

    <article class="card-list flex flex-column primary darken-1">
      <div class="card-icon-wrap">
        <v-icon
          class="card-icon"
          size="102"
          color="white"
          v-animate-onscroll.repeat="{
            down: 'animate__animated animate__slideInUp',
            up: 'animate__animated animate__slideInDown',
          }"
          >mdi-eye-outline</v-icon
        >
      </div>
      <h4 class="card-title text-h4 white--text">Visão</h4>
      <p class="card-text">
        Construção de um mundo com condições mais dignas para se viver.
      </p>
    </article>

    <article class="card-list flex flex-column primary darken-2">
      <div class="card-icon-wrap">
        <v-icon
          class="card-icon"
          size="102"
          color="white"
          v-animate-onscroll="{
            down: 'animate__animated animate__slideInUp',
            up: 'animate__animated animate__slideInDown',
          }"
          >mdi-hand-heart-outline</v-icon
        >
      </div>
      <h4 class="card-title text-h4 white--text">Valores</h4>
      <p class="card-text">
        Fraternidade, Solidadariedade, Empatia e Comprometimento
      </p>
    </article>
  </section>
</template>

<script>
export default {};
</script>

<style>
.card-list-container {
  width: calc(100% + 32px);
  min-height: 70px;
  /* background-color: antiquewhite; */
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  /* margin: 12px -12px; */
}
.card-list {
  padding: 32px 12px;
  min-width: 30%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  /* color: white; */
}
.card-icon {
  font-size: 48px;
}
.card-title {
  margin: 12px 0;
}
.card-text {
  color: var(--v-textPrimary-lighten2);
  text-align: center;
}
</style>