var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"card-list-container flex flex-column flex-lg-row",attrs:{"id":"missao"}},[_c('article',{staticClass:"card-list flex flex-column primary"},[_c('div',{staticClass:"card-icon-wrap"},[_c('v-icon',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:({
            down: 'animate__animated animate__slideInUp',
            up: 'animate__animated animate__slideInDown',
          }),expression:"{\n            down: 'animate__animated animate__slideInUp',\n            up: 'animate__animated animate__slideInDown',\n          }"}],staticClass:"card-icon",attrs:{"size":"102","color":"white"}},[_vm._v("mdi-bullseye-arrow")])],1),_c('h4',{staticClass:"card-title text-h4 white--text"},[_vm._v("Missão")]),_c('p',{staticClass:"card-text"},[_vm._v(" Congregar pessoas com espírito de voluntariado para realização de ações que possam melhorar o bem-estar físico, mental e emocional de pessoas com as mais diversas necessidades. ")])]),_c('article',{staticClass:"card-list flex flex-column primary darken-1"},[_c('div',{staticClass:"card-icon-wrap"},[_c('v-icon',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll.repeat",value:({
            down: 'animate__animated animate__slideInUp',
            up: 'animate__animated animate__slideInDown',
          }),expression:"{\n            down: 'animate__animated animate__slideInUp',\n            up: 'animate__animated animate__slideInDown',\n          }",modifiers:{"repeat":true}}],staticClass:"card-icon",attrs:{"size":"102","color":"white"}},[_vm._v("mdi-eye-outline")])],1),_c('h4',{staticClass:"card-title text-h4 white--text"},[_vm._v("Visão")]),_c('p',{staticClass:"card-text"},[_vm._v(" Construção de um mundo com condições mais dignas para se viver. ")])]),_c('article',{staticClass:"card-list flex flex-column primary darken-2"},[_c('div',{staticClass:"card-icon-wrap"},[_c('v-icon',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:({
            down: 'animate__animated animate__slideInUp',
            up: 'animate__animated animate__slideInDown',
          }),expression:"{\n            down: 'animate__animated animate__slideInUp',\n            up: 'animate__animated animate__slideInDown',\n          }"}],staticClass:"card-icon",attrs:{"size":"102","color":"white"}},[_vm._v("mdi-hand-heart-outline")])],1),_c('h4',{staticClass:"card-title text-h4 white--text"},[_vm._v("Valores")]),_c('p',{staticClass:"card-text"},[_vm._v(" Fraternidade, Solidadariedade, Empatia e Comprometimento ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }