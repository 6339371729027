<template>
  <div style="margin: 0">
    <hero-forms></hero-forms>
    <quem-somos></quem-somos>
    <missao-valores></missao-valores>
    <campanhas />
    <pq-voluntario></pq-voluntario>
    <form-voluntario></form-voluntario>
    <form-doacao></form-doacao>
    <the-footer></the-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import HeroForms from "@/components/HeroForms.vue";
import QuemSomos from "@/components/QuemSomos.vue";
import MissaoValores from "@/components/MissaoValores.vue";
import Campanhas from "@/components/Campanhas.vue";
import PqVoluntario from "@/components/PqVoluntario.vue";
import FormVoluntario from "@/components/FormVoluntario.vue";
import FormDoacao from "@/components/FormDoacao.vue";
import TheFooter from "@/components/TheFooter.vue";

export default {
  name: "Inicio",
  components: {
    HeroForms,
    QuemSomos,
    MissaoValores,
    Campanhas,
    PqVoluntario,
    FormVoluntario,
    FormDoacao,
    TheFooter,
  },
};
</script>

<style>
.s-down {
  animation: heroClip 3s;
}
.s-up {
  animation: heroClip 3s;
}

@keyframes heroClip {
  0% {
    clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0 0%, 100% 10%, 100% 100%, 0 100%);
  }
}
</style>
