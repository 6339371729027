<template>
  <section class="sessao" id="formVoluntario">
    <div style="margin-top: -104px; margin-bottom: 104px"></div>
    <h2 class="section-title text-h4 ma-3 mb-0">COMO SER UM VOLUNTÁRIO</h2>
    <div class="citacao">
      <span class="aspas primary--text">“</span> O voluntariado contribui não só
      para o acréscimo de bem-estar, como também a diminuição de sintomas
      depressivos e melhora da qualidade de vida. Alguns estudos destacam,
      inclusive, o trabalho voluntário como estratégia de promoção de saúde,
      principalmente para a população idosa
      <span class="aspas primary--text">”</span>
    </div>
    <!-- <h1 class="animate__animated animate__bounce">An animated element</h1> -->
    <v-sheet
      color="primary"
      elevation="1"
      shaped
      id="formCardVoluntario"
      class="form-destaque pa-8 ma-4 animate__animated"
    >
      <form>
        <v-text-field
          v-model="name"
          :error-messages="nameErrors"
          :counter="50"
          label="Nome"
          required
          filled
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        ></v-text-field>

        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          label="E-mail"
          required
          filled
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
        ></v-text-field>

        <v-text-field
          v-model="cel"
          type="tel"
          v-mask="['(##) ####-####', '(##) #####-####']"
          :error-messages="celErrors"
          label="Telefone"
          required
          filled
          @input="$v.cel.$touch()"
          @blur="$v.cel.$touch()"
        ></v-text-field>

        <v-checkbox
          v-model="checkbox"
          label="Mora em Lençois?"
          required
          color="secondary darken-2"
        ></v-checkbox>

        <v-btn
          class="mr-4"
          @click="submit"
          color="accent"
          :loading="loading"
          :disabled="loading"
        >
          Enviar
        </v-btn>
      </form>
    </v-sheet>

    <v-snackbar
      :timeout="20000"
      :multi-line="true"
      v-model="alert"
      shaped
      :color="alertError ? 'error' : 'success'"
      min-width="120px"
      max-width="90vw"
      elevation="4"
      class="alerta-bottom"
    >
      <div v-if="alertError">
        <p class="mt-2">
          Problemas no envio do formulário. <br />
          Por favor tente novamente mais tarde.
        </p>
        <p>Ou envie um email para reformandovidasong@gmail.com</p>
      </div>

      <div v-else>
        <p class="mt-2">Formulário enviado com sucesso.</p>
        <p>
          Agradecemos seu apoio. <br />
          Logo entraremos em contato.
        </p>
      </div>

      <!-- <template v-slot:action="{ attrs }"> -->
      <v-icon
        @click="alert = false"
        style="position: absolute; top: 8px; right: 8px"
      >
        mdi-close
      </v-icon>
      <!-- </template> -->
    </v-snackbar>
  </section>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email, helpers } from "vuelidate/lib/validators";
const cel = helpers.regex(
  "cel",
  /^(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/
);

const animateCSS = (element, animation, prefix = "animate__") => {
  return new Promise((resolve) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);

    node.classList.add(`${prefix}animated`, animationName);

    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve("Animation ended");
    }

    node.addEventListener("animationend", handleAnimationEnd, { once: true });
  });
};

export default {
  mixins: [validationMixin],

  validations: {
    name: { required, maxLength: maxLength(50) },
    email: { required, email },
    cel: { cel },
  },

  data: () => ({
    name: "",
    email: "",
    cel: "",
    checkbox: false,
    alert: false,
    alertError: false,
    loading: false,
  }),

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Por favor digite um nome menos de 50 caracteres.");
      !this.$v.name.required && errors.push("Esse campo é obrigatório.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Por favor, digite um email válido.");
      !this.$v.email.required && errors.push("Esse campo é obrigatório.");
      return errors;
    },
    celErrors() {
      const errors = [];
      if (!this.$v.cel.$dirty) return errors;
      !this.$v.cel.cel && errors.push("Por favor, digite um número válido.");
      return errors;
    },
  },

  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$vuetify.goTo("#formCardVoluntario", {
          duration: 600,
          offset: 80,
          easing: "easeInCubic",
        });
        await animateCSS("#formCardVoluntario", "shakeX");
      } else {
        await this.enviarEmail();
      }
    },
    enviarEmail() {
      const body = {
        name: this.name,
        email: this.email,
        cel: this.cel,
        ehLencois: this.checkbox,
      };
      this.loading = true;
      fetch("/.netlify/functions/send-email", {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((res) => {
          this.alertError = res.status !== 200;
          this.loading = false;
          this.alert = true;
          if (!this.alertError) this.limpaForm();
        })
        .catch(() => {
          this.alert = true;
          this.loading = false;
          this.alertError = true;
        });
    },
    limpaForm() {
      this.name = "";
      this.email = "";
      this.cel = "";
      this.checkbox = false;
      this.$v.$reset();
    },
  },
};
</script>

<style>
.aspas {
  font-size: 1.8rem;
}
.alerta-bottom {
  /* padding: 0 32px 0 0; */
}
#formCardVoluntario {
  --animate-delay: 1s;
}
</style>