<template>
  <section class="sessao d-flex flex-wrap" id="pqVoluntario">
    <div style="margin-top: -104px; margin-bottom: 104px"></div>
    <h2 class="section-title text-h4 ma-3 mb-0">POR QUE SER VOLUNTÁRIO?</h2>
    <p class="section-desc pa-2 pt-2">
      Ser voluntário é uma atividade muito nobre porém é mais complexa do que
      aparenta, pois envolve a doação do tempo, dedicação e esforços em prol do
      bem-estar de uma causa.
      <br />
      <br />
      As pessoas optam para fazer algum trabalho voluntário por diversos
      motivos:
    </p>
    <div style="min-width: 300px; width: 50%; flex-grow: 1">
      <div class="list-container">
        <article
          class="list-item flex flex-column"
          v-for="(motivo, i) in motivos"
          :key="`motivo-${i}`"
        >
          <div
            class="card-icon-wrap"
            v-animate-onscroll.repeat="'animate__animated animate__zoomIn'"
          >
            <v-icon class="card-icon" size="48" color="primary">{{
              motivo.icon
            }}</v-icon>
          </div>
          <h4 class="card-title text-h4 text-h6">{{ motivo.title }}</h4>
        </article>
      </div>
      <p class="text-center text-h6">E muitos outros motivos.</p>
    </div>
    <!-- <iframe width="699" height="393" src="https://www.youtube.com/embed/9uS_QT-ldaM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        position: relative;
        padding: 4px;
      "
    >
      <h3 class="text-center text-h5">Primeira ação da ONG Reforma Vidas</h3>
      <div class="video-container" style="align-self: center">
        <iframe
          class="video"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/2mEyZgWjqiM"
          title="Primeira ação da ONG "
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  created() {},
  data: () => {
    return {
      motivos: [
        {
          title: "Religiosidade",
          icon: "mdi-shield-cross",
        },
        {
          title: "Retribuição à sociedade",
          icon: "mdi-hand-heart",
        },
        {
          title: "Treinar ou aprender novas habilidades",
          icon: "mdi-hammer-wrench",
        },
        {
          title: "Melhorar o curriculum",
          icon: "mdi-newspaper-variant-outline",
        },
        {
          title: "Fazer novas amizades",
          icon: "mdi-account-group",
        },
        {
          title: "Melhorar o autoconhecimento",
          icon: "mdi-shield-account",
        },
        {
          title: "Expandir a visão de mundo",
          icon: "mdi-earth",
        },
        {
          title: " Melhorar a comunicação",
          icon: "mdi-comment-multiple-outline",
        },
      ],
    };
  },
};
</script>

<style>
.list-container {
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  flex-wrap: wrap;
}
.list-item {
  display: flex;
  align-items: center;
  width: 45%;
  padding: 12px;
}
.card-title {
  text-align: center;
}
.video-container {
  /* width is set as 100% here. any width can be specified as per requirement */
  width: 100%;
  padding-top: 56.25%;
  height: 0px;
  position: relative;
  min-width: 300px;
}

.video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.section-desc {
  width: 100%;
}
</style>