<template>
  <v-app id="app">
    <v-app-bar
      id="app-bar"
      fixed
      color="primary"
      dark
      elevate-on-scroll
      class="pa-1"
      height="72"
    >
      <a
        class="d-flex align-center link-texto"
        @click="$vuetify.goTo('#home', options)"
      >
        <v-img
          alt="ONG LOGO"
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="40"
          v-ripple
        />

        <h4
          alt="ONG REFORMANDO VIDAS"
          class="
            text-decoration-none
            shrink
            mt-1
            hidden-sm-and-down
            text-h6
            white--text
          "
          style="text-decoration: none"
          width="100"
        >
          REFORMANDO VIDAS
        </h4>
      </a>

      <v-spacer></v-spacer>

      <scrollactive
        class="nav d-none d-sm-flex"
        style="display: flex"
        active-class="item-activo"
        :offset="75"
        :clickToScroll="false"
        :alwaysTrack="true"
      >
        <a
          :data-section-selector="item.target"
          class="
            scrollactive-item
            menu-item
            text-center
            white--text
            font-weight-medium
          "
          v-for="(item, index) in items"
          :key="`${index}-menu`"
          @click="$vuetify.goTo(item.target, options)"
        >
          {{ item.title }}
        </a>
      </scrollactive>

      <v-spacer></v-spacer>

      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="hidden-sm-and-up"
      ></v-app-bar-nav-icon>

      <v-btn
        @click="$vuetify.goTo('#formVoluntario', options)"
        class="mr-4 darken-2"
        style="margin-left: 8px"
        color="accent"
        dark
      >
        <span class="mr-2">SEJA VOLUNTÁRIO</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      class="primary"
      v-model="drawer"
      fixed
      temporary
      clipped
      app
    >
      <v-list>
        <v-list-item v-ripple class="pa-2 d-flex justify-center">
          <v-img
            alt="ONG LOGO"
            class="shrink mr-2"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="72"
            @click="
              $vuetify.goTo('#home', options);
              drawer = !drawer;
            "
          />
        </v-list-item>

        <v-list-item
          link
          @click="
            $vuetify.goTo('#home', options);
            drawer = !drawer;
          "
        >
          <v-list-item-content>
            <v-list-item-title
              class="text-h5 text-center font-weight-medium"
              style="white-space: normal"
            >
              ONG REFORMANDO VIDAS
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav class="pa-0 pt-2">
        <scrollactive
          active-class="item-activo"
          :offset="75"
          :clickToScroll="false"
          :alwaysTrack="true"
        >
          <v-list-item
            class="pa-0"
            v-for="(item, index) in items"
            :key="`${index}-navdrawer`"
            link
            @click="
              $vuetify.goTo(item.target, options);
              drawer = !drawer;
            "
          >
            <a
              style="width: 100%"
              :data-section-selector="item.target"
              class="
                ml-2
                scrollactive-item
                menu-item
                text-center
                white--text
                font-weight-medium
                d-flex
                align-center
                link-texto
                black--text
                h-6
              "
            >
              <div>
                {{ item.title }}
              </div>
            </a>
          </v-list-item>
        </scrollactive>
      </v-list>
    </v-navigation-drawer>

    <v-main style="overflow-x: hidden">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Scrollactive from "vue-scrollactive/src/scrollactive.vue";
export default {
  name: "App",

  data: () => ({
    drawer: false,
    options: {
      duration: 600,
      offset: 74,
      easing: "easeInCubic",
    },
    items: [
      {
        title: "Quem somos",
        target: "#quem-somos",
      },
      {
        title: "Nossas Causas",
        target: "#causas",
      },
      {
        title: "Voluntariado",
        target: "#pqVoluntario",
      },
    ],
  }),
  components: {
    Scrollactive,
  },
};
</script>

<style>
@import url("/src/global.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,500;1,100;1,300;1,700&display=swap");
html {
  scroll-behavior: smooth;
}
.sessao:nth-child(even) {
  background-color: rgb(234, 244, 250);
}
body #app,
.v-application.text-h4 {
  max-width: 99.9vw;
  width: 100vw;
  min-width: 360px;
  font-family: "Montserrat", sans-serif !important;
}
#app-bar.v-app-bar--hide-shadow {
  background-color: rgba(0, 0, 0, 0) !important;
}
.link-texto,
.link-texto:link,
.link-texto:visited,
.link-texto:hover,
.link-texto:active {
  text-decoration: none !important;
}

.menu-item {
  height: 72px;
  margin-top: -8px;
  padding: 8px;
  display: flex;
  align-items: center;
}
.item-activo {
  background-color: var(--v-primary-darken2);
}
</style>